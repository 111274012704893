<template>
    <landing :breadCrumbs="breadCrumbs" :shopName="shopName">
        <v-container>
            <v-row
                id="description"
                justify="center"
                :class="smAndUp ? 'mb-15' : null"
            >
                <v-col cols="auto" align="center">
                    <heading
                        ><span class="red--text">行</span
                        >徳駅前一丁目店</heading
                    >
                </v-col>
            </v-row>

            <!-- 紹介文 -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">アクセス</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >行徳駅徒歩5分!</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">住所</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >千葉県市川市行徳駅前1丁目9-19</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">営業時間</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平日13:00～22:00/土日10:00～18:00<br />※時間外は応相談</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">定休日</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >月・木</span
                        >
                    </div>
                </v-col>
            </v-row>

            <!-- 代表イメージ -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img height="360px" :src="imageMv"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="bottom center"
                            height="360px"
                            :src="shopImage"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col v-if="!smAndUp" cols="12" align="center">
                    <v-icon
                        small
                        color="red darken-2"
                        @click="$vuetify.goTo('#target')"
                        >fas fa-chevron-down</v-icon
                    >
                    駅からのご案内
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 360px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1717468540973!6m8!1m7!1sNELYoG2FYUSTcWLMDOueAw!2m2!1d35.68342656108592!2d139.9181393350258!3f157.69074056017217!4f-7.115452471148103!5f0.7820865974627469"
                            width="100%"
                            height="360"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </v-card>
                </v-col>
            </v-row>

            <!-- 店舗個別メッセージ -->
            <shop-message :message="this.message" :shopImage="trainerImage" />

            <!-- ナビゲーション -->
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <heading
                        ><span id="target" class="red--text">行徳駅</span
                        >からお越しの方</heading
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">1</span>.
                            改札を出て右側のロータリー（交番側）にいきます。
                        </div></v-card-title
                    >
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessA"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">2</span>.
                            星野珈琲の方に向かって交差点の信号を渡ります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessB"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span
                                ><span class="red--text text-md-h6">3</span>.
                                スリーエフの方へ進み、右側の道に入ります。</span
                            >
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessC"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">4</span>.
                            薬局を左に曲がります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessD"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">5</span>.
                            カフェやステーキ屋をまっすぐ進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessE"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">6</span>.
                            ラーメン屋（小むろ）、焼き肉屋（炙りや）の向かい側の建物です。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessF"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">7</span>.
                            クリーニング屋の右隣がREVOISTです。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessG"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">8</span>.
                            こちらがREVOIST行徳駅前一丁目店です。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessH"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </landing>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import ShopMessage from '@/components/landing/ShopMessage.vue'
import Landing from '@/views/Landing.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document.querySelector('meta[name="description"]').setAttribute(
            'content',
            `パーソナルジム REVOISTの13号店です！
行徳駅から徒歩5分、行徳支所前通りにあるラーメン屋小むろさん、焼肉屋炙りやさんの向かいにあるジムです！\
筋トレ＝筋肉を大きくするというイメージがありますが、筋トレには、太りづらい身体になる、美しいボディラインが手に入るなど、様々な効果があります！
筋トレも食事も、まずは無理のない範囲から始めて、僕と一緒に目標達成に向けて頑張っていきましょう！
`
        )
    },
    components: {
        Heading,
        FooterContents,
        ShopMessage,
        Landing,
    },
    data() {
        return {
            shopName: '行徳駅前一丁目店',
            center: {
                lat: 35.68346564858538,
                lng: 139.91819083955167,
            },
            zoom: 18,
            markers: [
                {
                    position: {
                        lat: 35.68346564858538,
                        lng: 139.91819083955167,
                    },
                    title: '行徳駅前一丁目店',
                },
            ],
            message: `パーソナルジム REVOISTの13号店です！<br />
行徳駅から徒歩5分、行徳支所前通りにあるラーメン屋小むろさん、焼肉屋炙りやさんの向かいにあるジムです！<br />
<br />
筋トレ＝筋肉を大きくするというイメージがありますが、筋トレには、太りづらい身体になる、美しいボディラインが手に入るなど、様々な効果があります！<br /><br />
筋トレも食事も、まずは無理のない範囲から始めて、僕と一緒に目標達成に向けて頑張っていきましょう！
<br />
<br />
行徳駅前一丁目店トレーナー：船津一誠
`,
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '店舗一覧・アクセス',
                    disabled: false,
                    href: 'gyms',
                },
                {
                    text: '行徳駅前一丁目店',
                    disabled: true,
                    href: 'access-12',
                },
            ]
        },

        imageMv() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_13/gyotokuekimae1chome_9.png'
                : 'static/pc_access_13/gyotokuekimae1chome_9.png'
        },
        accessA() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_13/gyotokuekimae1chome_1.png'
                : 'static/pc_access_13/gyotokuekimae1chome_1.png'
        },
        accessB() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_13/gyotokuekimae1chome_2.png'
                : 'static/pc_access_13/gyotokuekimae1chome_2.png'
        },
        accessC() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_13/gyotokuekimae1chome_3.png'
                : 'static/pc_access_13/gyotokuekimae1chome_3.png'
        },
        accessD() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_13/gyotokuekimae1chome_4.png'
                : 'static/pc_access_13/gyotokuekimae1chome_4.png'
        },
        accessE() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_13/gyotokuekimae1chome_5.png'
                : 'static/pc_access_13/gyotokuekimae1chome_5.png'
        },
        accessF() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_13/gyotokuekimae1chome_6.png'
                : 'static/pc_access_13/gyotokuekimae1chome_6.png'
        },
        accessG() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_13/gyotokuekimae1chome_7.png'
                : 'static/pc_access_13/gyotokuekimae1chome_7.png'
        },
        accessH() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_13/gyotokuekimae1chome_8.png'
                : 'static/pc_access_13/gyotokuekimae1chome_8.png'
        },
        shopImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_13/gyotokuekimae1chome_8.png'
                : 'static/pc_access_13/gyotokuekimae1chome_8.png'
        },
        trainerImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/trainer/行徳駅前一丁目店 トレーナー.jpg'
                : 'static/landing/trainer/行徳駅前一丁目店 トレーナー.jpg'
        },
    },
}
</script>

<style lang="scss">
.navigation {
    min-height: 3em;
}
</style>
